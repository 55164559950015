<template>
  <div style="width:100%;height:100%;margin-top:1.5%">
    <div style="position:relative;width:65%;height:100%;margin-left:17.5%;color:#000000;">
      <h1>关于我们</h1>
      <h3 style="margin-top:0.5%;color:#5C5C5C">About   us</h3>
      <span style="position: relative;top: -1.5%;display:inline-block;width:4%;height:0.5%;background:#00A8FF"></span>
      <p style="text-align:left;font-weight:Bold"><img src="../../assets/img/公司简介箭头.png">&nbsp;&nbsp;公司简介：</p>
      <p style="text-align:left;margin-top:1.5%;line-height:35px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“东方丝路”作为国内领先的沉浸式数字化体验与管理服务提供商，我们不断深入探索数学的奥秘、图形学的精妙、计算机软件的深度以及地理学的广博，致力于推动数学、图形学、计算机软件、地理学及其交叉学科的融合与进步。我们为“数字孪生”和“元宇宙”这两大前沿科技领域，精心打造了一系列最具创新性和可持续发展潜力的解决方案，从而在行业内树立了领导者的标杆。<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司凭借强大的研发实力，成功推出了拥有完全自主知识产权的三维可视化“数字孪生”引擎软件 —— “Clone Space”，“地理信息”引擎软件 —— “Magic GIS”，大模型引擎——“Clone LM”，物联网中台 —— “Clone LoT”、大数据中台 —— “Clone BD”。这些创新型的产品和技术在智慧城市、智慧园区、智慧交通、智慧水利、智慧公安、应急管理、智慧电力、智慧矿山等多个领域得到了广泛部署和应用，赢得了广大用户的高度认可和一致好评。我们坚信，这些卓越的技术产品和解决方案将为社会带来更多价值，推动行业数字化转型迈向新的高度。
      </p>
			<div style="display: flex;">
				<div style="width: 50%;">
					<p style="text-align:left;margin-top:1%;line-height: 60px;"><img src="../../assets/img/公司简介箭头.png">&nbsp;&nbsp;<span style="font-weight:Bold">企业宗旨：</span> 营造数字生态共筑孪生应用</p>
					<!-- <p style="text-align:left;margin-top:1%"><img src="../../assets/img/公司简介箭头.png">&nbsp;&nbsp;<span style="font-weight:Bold">企业使命：</span>让数字孪生更好服务于实体经济、新质经济。</p> -->
					<p style="text-align:left;margin-top:1%;"><img src="../../assets/img/公司简介箭头.png">&nbsp;&nbsp;<span style="font-weight:Bold">企业使命：</span> 数字孪生赋能数字中国</p>
				</div>
				<div style="width: 50%;">
					<p style="text-align:left;margin-top:1%;line-height: 60px;"><img src="../../assets/img/公司简介箭头.png">&nbsp;&nbsp;<span style="font-weight:Bold">核心价值观：</span> 诚信&nbsp;&nbsp;&nbsp;&nbsp;精专&nbsp;&nbsp;&nbsp;&nbsp;创新&nbsp;&nbsp;&nbsp;&nbsp;合作</p>
					<p style="text-align:left;margin-top:1%;"><img src="../../assets/img/公司简介箭头.png">&nbsp;&nbsp;<span style="font-weight:Bold">企业愿景：</span>提供实用、易用、好用、爱用的软件</p>
				</div>
			</div>
      <el-button @click="toIntro()" type="primary" round style="backgound:#00A8FF;position: relative;top: 6%;">
        <span style="vertical-align: middle" > 了解更多&nbsp; </span>
         <el-icon style="vertical-align: middle">
          <Right />
        </el-icon>
      </el-button>
    </div>
  </div>
</template>

<script setup>
	import {ref,defineEmits} from 'vue'
	import {Right} from '@element-plus/icons-vue'
	const emit = defineEmits(['toIntro'])
	function toIntro() {
		emit('toIntro','fiveth');
	}
</script>

<style>

</style>