<template>
  <div style="width:100%;height:100%;overflow-y:scroll;position:relative">
    <div style="width:100%;height:55%;">
      <img style="width:100%;height:100%" src="@/assets/img/公司简介1.png">
    </div>
    <div class="ProfileContainer" style="width:100%;height:55%;">
      <div style="position:relative;width:65%;height:100%;margin-left:17.5%;color:#000000;top:8.5%">
      <h1>公司简介</h1>
      <h3 style="margin-top:0.5%;color:#5C5C5C">Company Profile</h3>
      <span style="position: relative;top: -1.5%;display:inline-block;width:5%;height:1%;background:#00A8FF"></span>
      <p style="text-align:left;margin-top:1.5%;line-height:30px;"> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“东方丝路”作为国内领先的沉浸式数字化体验与管理服务提供商，我们不断深入探索数学的奥秘、图形学的精妙、计算机软件的深度以及地理学的广博，致力于推动数学、图形学、计算机软件、地理学及其交叉学科的融合与进步。我们为“数字孪生”和“元宇宙”这两大前沿科技领域，精心打造了一系列最具创新性和可持续发展潜力的解决方案，从而在行业内树立了领导者的标杆。
        <br> <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司凭借强大的研发实力，成功推出了拥有完全自主知识产权的三维可视化“数字孪生”引擎软件 —— “Clone Space”，“地理信息”引擎软件 —— “Magic GIS”，大模型引擎——“Clone LM”，物联网中台 —— “Clone LoT”、大数据中台 —— “Clone BD”。这些创新型的产品和技术在智慧城市、智慧园区、智慧交通、智慧水利、智慧公安、应急管理、智慧电力、智慧矿山等多个领域得到了广泛部署和应用，赢得了广大用户的高度认可和一致好评。我们坚信，这些卓越的技术产品和解决方案将为社会带来更多价值，推动行业数字化转型迈向新的高度。
      </p>
      </div>
    </div>
    <div class="MissionContainer" style="width:100%;height:67%;"></div>
    <CooperativePartner style="width:100%;height:80%"></CooperativePartner>
    <Footers style="width:100%;height:27%;"></Footers>
  </div> 
</template>

<script src="./js/CompanyProfile.js">
</script>

<style src="./css/CompanyProfile.css" scoped>

</style>