import {ref,reactive,onMounted} from 'vue'
import Footers from '../../Footers/Footers.vue'
export default {
  components:{
    Footers
  },
  setup () {
    let activeName=ref('智慧交通')
    let data=ref([
      {
        name:'智慧交通',
        img1:require('@/assets/img/智慧交通1.png'),
        img2:require('@/assets/img/智慧交通2.png'),
				titleText:'',
				content1:'智慧交通系统是通过大数据技术、物联技术、遥感技术实现了在道路资产管理、道路巡护、道路养护、道路设计规划等方面的全生命周期全面覆盖与精细管理。',
				content2:'为四好农村路的建设提供了坚实的科学依据和先进的技术支持，从而在根源上改善交通环境，营造更加便捷、安全的出行体验。'

        // titleText:' 随着智慧交通系统的不断演进与精细化管理，我们有望见证交通运行效率的显著提升，交通事故发生率的有效降低，以及交通拥堵问题的缓解，进而促进道路安全水平的全面提升。',
        // content1:' 在道路资产的管理、巡护、养护等关键环节，智慧交通系统能够实现对道路全生命周期的全面覆盖和精细管理，为四好农村路的建设提供坚实的科学依据和先进的技术手段。',
				// content2:' 智慧交通系统的构建也将极大地优化市民的出行体验，提升他们的生活品质，从而在根本上改善交通环境。'
				
				// content1:' 为了响应国家“乡村振兴”战略、数字化进程及进一步落实习主席对农村公路的重要批示，提高县道及以上公路精细化管理和信息化管理水平，加快推进信息资源整合和数字化、可视化操作，特此为交通运输局搭建了交通运输综合管理平台。',
        // content2:' “四好农村路智慧化管理系统”是智能数据化的人机交互平台管理系统，主要包括路产信息、路产状态、养护业务、养护考核监督、动态治超、安全监控、应急保障、路长制及BI数据可视化等子系统。该平台依托南湖区应急指挥平台开展建设，通过可视化平台、手机APP等形成“发现问题——及时处理——审查复核”的无缝闭环，打造创新高效的全新工作模式，合理有效安排相关资源及巡查维护计划与活动，提升交通管理效能。'  
      },
      {
        name:'智慧警务',
        img1:require('@/assets/img/智慧警务1.png'),
        img2:require('@/assets/img/智慧警务2.png'),
				

				titleText:'',
				content1:'智慧警务是依托于网络化、物联化、可视化及智能化的尖端技术。',
				content2:'实现了辖区警力资源分布可视化、重点场所三维化、辖区范围清晰化、指挥调度协同化、资源配置标准化，为公安系统的现代化征程注入了勃勃生机与无限可能。'
				
				// titleText:'',
        // content1:'智慧警务，是通过网络化、物联化、可视化、智能化的先进手段，促进公安系统内部各功能模块的深度融合与高效协同，致力于实现警务信息的深度整合、高度共享和广泛应用。',
        // content2:'这一目标的实现，标志着警务工作迈向了一个全新的理念和模式，实现了警力资源统一管理、统一调度，为公安系统的现代化进程注入了新的活力。'
				
				// content2:'   最终形成一套具备"预测预警、稳态防控、精准打击、合成作战、扁平指挥、动态管理、保障有力"的在线式智慧警务实战体系，实现公安工作的智能化转型，全面推动公安"质量变革、效率变革、动力变革"，实现了公安机关战斗力和社会综合治理能力的跨越式发展，创造平安稳定的社会环境。'   
      },
      {
        // name:'智慧城市',
        // name:'智慧水务',
        name:'水利数字孪生',
        img1:require('@/assets/img/智慧水务1.png'),
        img2:'',
				
				titleText:'',
				content1:'水利数字孪生系统是凭借其先进的GIS技术、BIM技术、大数据技术手段，实现了对水厂、供水设施、供水管网运行状态的全面、实时监测，能够精准预测并把握供水管网中的水量、水压、水质等核心参数的变化趋势，从而有效确保供水管网的安全、高效运行，并显著降低能源消耗。该系统还通过深度整合历史数据与实时数据，运用先进的数据分析手段，为供水管理提供了坚实的科学基础与强有力的决策支撑。',
				content2:''
				
        // titleText:'',
				// content1:'水利数字孪生建设依托于数字孪生技术以及其他新一代信息技术的融合应用。该体系深入优化了传统业务流程，科学地推进了数字孪生技术在水利工程、供水工程中的深入实施。数字孪生供水平台具备实时监测供水管网运行状态的能力，能够精确预测供水管网中的水量、水压、水质等多项关键指标，确保了供水管网的运行安全、效率提升以及能源消耗的降低。通过综合分析历史数据与实时数据，该平台为供水管理提供了坚实的科学依据和有力的决策支持。',
				// content2:''
        
				// content1:'东方丝路“数字孪生”引擎“Clone World”建设智慧城市智能运营中心（IOC），构建数字孪生城市，能够有效融合政府各职能部门现有数据资源，支持从宏观到微观，对资源环境、基础设施、交通运输、社会治理、人口民生、产业经济、社会舆情、公共安全等领域的核心指标进行态势监测与可视分析，对城市运行态势进行全面感知、综合研判，帮助城市管理者提高城市运营管理水平、驱动城市管理走向精细化。',
        // content2:''   
      },
      {
        name:'智慧园区',
        img1:require('@/assets/img/智慧园区1.png'),
        img2:require('@/assets/img/智慧园区2.png'),
        //

				titleText:'',
				content1:'智慧园区是依托于物联网、云计算、地理信息、大数据分析以及人工智能等尖端技术的深度融合与广泛应用。',
				content2:'在园区内的基础设施、设备管理、人员管理、物资管理等领域实现了场景全面感知、业务无缝连接、问题深度分析，趋势动态分析。不仅能极大地提升了园区的运营效率，还可以显著增强了园区的安全保障能力。'
				
				// titleText:'',
        // content1:'智慧园区建设是基于物联网、云计算、大数据分析以及人工智能等前沿技术的深度融合与应用。',
				// content2:'通过这些技术的综合运用，园区内的基础设施、设备以及人员的动态得以全面感知、无缝连接、深度分析，并实现智能化的管理。这一过程不仅显著提升了园区的运营效率，还增强了园区的安全保障，同时极大地改善了园区内居住与工作的环境舒适度。'
        
				// content2:'此外，还能够对园区产业、招商、安防、资产、基础设施、能效、环境空间等管理领域的关键指标进行可视化监测分析，辅助管理者全面掌控园区运行态势，有效提升园区的运营效率与运营水平，为用户进行园区运维管理提供“一站式”可视化决策支持，可广泛应用于政府园区、地产园区、校园运营、工业园区、景区运营等领域。'   
      },
      {
        name:'智慧矿山',
        img1:require('@/assets/img/智慧矿山1.png'),
        img2:require('@/assets/img/智慧矿山2.png'),
				
				titleText:'',
				content1:'智慧矿山是通过智能化、数字化、网络化等先进技术手段，实现了矿山地下地上三维可视化、预测预警智能化、设备监测全面化、人员定位实时化、安全管理标准化、应急模拟流程化。',
				content2:'智慧矿山不仅能够显著提升矿山生产效率，有效降低能源消耗，保护环境，还能显著降低矿山灾害风险，提高矿工的工作安全，为矿业的可持续发展奠定坚实基础。'
				
				// titleText:'',
				// content1:'智慧矿山是矿业未来发展的必然趋势，它将传统矿业与现代信息技术深度融合，通过智能化、数字化、网络化等先进技术手段，实现矿山生产、管理、监控等各环节的智能化、高效化、安全化。',
				// content2:'智慧矿山不仅能够显著提升矿山生产效率，有效降低能源消耗，还能显著降低矿山灾害风险，保护环境，提高矿工的工作安全性，为矿业的可持续发展奠定坚实基础。',
				
				// titleText:'以GIS、物联网、大数据、云计算、人工智能等新型技术为基础，集矿山的各类传输网络、感知系统、自动化系统、管理系统，打通数据 “孤岛”，构建统一、融合、 智能、高效的矿山大脑为一体，构建了一套具有主动感知、自动分析、深度学习、智能决策、科学指挥的智慧型矿山综合应急指挥调度系统。',
        // content1:'东方丝路“数字孪生”引擎“Orient Silk”建设的智慧矿山“综合应急指挥调度系统”，是严格按照全国安全生产信息化“一盘棋”、“一张网”、“一张图”、“一张表”的总体目标要求，结合煤矿自身特点建设的“应急指挥调度系统”，系统基于煤矿“一张图”的管理理念，梳理智慧煤矿标准规范体系，采用最新的空间信息、云计算、大数据、移动互联网等技术，研发了煤矿安全生产GIS一张图、云服务平台、物联网中台及大数据分析平台，为综合应急指挥调度系统提供物联网设备、空间数据和业务数据的集成与应用服务，实现煤矿应急指挥调度、集中管控及科学决策，提高防灾救灾能力和安全管理水平。',
        // content2:' 以GIS、物联网、大数据、云计算、人工智能等新型技术为基础，集矿山的各类传输网络、感知系统、自动化系统、管理系统，打通数据 “孤岛”，构建统一、融合、智能、高效的矿山大脑为一体，构建了一套具有主动感知、自动分析、深度学习、智能决策、科学指挥的智慧型矿山综合应急指挥调度系统。安全生产一直是我国的一项基本国策，是保护劳动者安全健康和发展生产力的重要工作，同时也是维护社会安定团结，促进国民经济稳定、持续、健康发展的基本条件。安全工作是煤炭企业的生命线、幸福线。没有安全，就没有生产、没有效益、没有矿区的稳定发展和职工个人的家庭幸福。 “智慧矿山综合应急指挥调度系统”是安全生产最重要的技术保障！ 《主要安全生产法律法规》： 1.《安全生产法》 2.《矿山安全法》 3.《煤炭法》 4.《煤矿安全监察条例》 5.《煤矿安全规程》（简称《规程》 6.《安全生产违法行为行政处罚办法》（简称《处罚办法》）'   
      },
      {
        // name:'智慧电网',
        name:'智慧电力',
        img1:require('@/assets/img/智慧电网1.png'),
        img2:require('@/assets/img/智慧电网2.png'),
				//

				titleText:'',
				content1:'智慧电力系统是通过一系列先进的3D模拟技术、智能化控制技术，实现了电力系统以运行维护自动化、实时监测可视化、数据共享标准化等核心能力。',
				content2:'该系统依托于地理信息的大数据处理能力，成功消除了传统电力系统中数据孤岛的现象，构建了一个更为智能化且可控的可视化电力管理模式。'
				
				// titleText:'',
				// content1:'智慧电力系统之显著优势在于其提供了一系列先进的智能化控制技术，这些技术赋予电力系统以自动化维护、在线检测、数据共享、实时监控等核心能力。',
        // content2:'该系统依托于基于云计算的大数据能力，成功消除了传统电力系统中数据孤岛的现象，构建了一个更为智能化且可控的可视化电力管理模式。',
        
				// titleText:'电力系统是社会民生，农业工业生产及国防等领域正常运作的重要的保障。稳定、可靠的供电是电力供应单位重要使命。如何让电力系统安全、可靠、高效、精准，经济、高质的运转一直是电力行业追求的目标。随着现代科技的高速发展，采用高科技手段赋能电力生产管理运营使这一目标不再遥远，也是实现电力系统数字化转型的必经之路和必然趋势。数字化电缆沟信息系统是实现电力系统数字化转型的第一步。 “孤岛”，构建统一、融合、 智能、高效的矿山大脑为一体，构建了一套具有主动感知、自动分析、深度学习、智能决策、科学指挥的智慧型矿山综合应急指挥调度系统。',
        // content1:'东方丝路“数字孪生”引擎“Clone World”建设的智慧电网“电缆沟大数据可视化平台”，将电缆沟、电缆、 设备基本信息、位置信息、 实时状态、运行参数、报 警信息通过移动网络实时 推送至手持端，并进行数 据的可视化展示，最终实现设备在手，掌握全局的目标。',
        // content2:'建设目标：\n 看：构建电缆沟完整性数据库系统，将缆沟信息、电缆信息、设备信息、监测信息、管理信息等全业务数据进行大融合；配合大数据可视化平台及地理信息系统对各类信息进行融合展示、信息串联，实现数据的全生命周期输出及多维度展示。\n判：基于电缆沟完整性数据库系统和行业标准和规范，与实际环境和事物结合，构建智慧化电缆沟评估平台，对电缆沟各类业务进行综合分析预判；将实时监测数据与电缆沟完整性数据相结合，实现隐患的提前预判，将隐患阻断在萌芽之前；与监控检测数据结合，精准定位隐患发生的位置，为隐患的处置赢得时间。\n管：基于电缆沟完整性数据库及智慧评估平台，构建电缆沟综合管控平台。实现对智慧评估平台的评估结果进行在线模式的专家组二次研判，产出科学、严谨、精准、信息完整的评估报告，指导电力系统的生产经营。配备桌面端和移动端管理平台，实现生产管理过程的信息化监管，实现完整的闭环管理目标。'   
      },
      {
        // name:'综合应急',
        name:'智慧地震',
        img1:require('@/assets/img/智慧地震1.png'),
        img2:'',
        
				titleText:'',
				content1:'智慧地震系统是通过整合地震监测、灾害评估、影响场生成、专题图制作、地震报告以及灾害调查等多项系统和数据，实现了地震应急响应和应急指挥。在地震发生时为灾害评估、救援行动以及灾后重建工作提供坚实可靠的决策支持。',
				content2:''
				
				// titleText:'',
        // content1:'智慧地震系统通过整合地震监测、灾害评估、影响场生成、专题图制作、地震报告以及灾害调查等多项系统和数据，实现了在非地震时期进行模拟演练；在地震发生时为灾害评估、救援行动以及灾后重建工作提供坚实可靠的决策支持。',
				// content2:''
				
				// content1:'东方丝路“数字孪生”引擎“Clone Future”建设的“地震灾害应急风险管理与服务系统”,是贯彻党中央、国务院以及各省委、省政府关于做好地震灾害防治工作的有关要求，切实做好地震灾害应急风险管理与服务工作，提升各省地震灾害应急风险综合防治能力，依据地震灾害防治相关技术标准，结合各省实际情况，建设的一套地震灾害应急风险管理与服务系统，系统从调查评价、监测预警、综合治理、风险管控和能力建设等多维度展开，最终实现了依法科学统一、有力有序有效地实施地震应急，最大程度减少人员伤亡和经济损失，维护社会正常秩序的建设目标。',
        // content2:'“地震灾害应急风险管理与服务系统”围绕应急管理部下发文件的总体标准要求，利用第一次全国自然灾害风险普查成果,结合各省实际需求，综合运用4G/5G、大数据、物联网、人工智能等前沿技术，建设立体化全息感知网络、天地一体化应急通信网络、协同联动的智能应用体系、安全可靠的运行保障体系、完整统一的标准规范体系，实现全省应急救援资源和能力的有机整合，建设具备统一指挥、专常兼备、反应灵敏、上下联动、平战结合，具有地方特色的地震灾害应急响应与服务技术体系，为各省应急信息化建设工作的跨越式发展奠定坚实基础。'   
      },
      {
        name:'智慧监狱',
        img1:require('@/assets/img/智慧监狱1.png'),
        img2:require('@/assets/img/智慧监狱2.png'),
				
				titleText:'',
				content1:'智慧监狱系统是依托尖端科技与创新管理策略，实现了对监狱的全面监控与高效管理，显著提升了监狱的安全水平与管理效能。',
				content2:'该系统精心构建了精细化的模型场景，提供了标准化的图层服务，实现了流程化的业务管理，并配备了可视化的指挥调度功能。'
				
				// titleText:'',
				// content1:'智慧监狱系统建设是集信息技术与智能化技术之大成的解决方案，通过采纳尖端科技与创新的管理策略，实现了对监狱全方位的监控与高效管理。',
        // content2:'其目的在于显著提升监狱的安全水平与管理效率。同时，该系统亦为在押人员提供全面的教育与培训服务，助力其完成个人改造，并为重返社会做好充分准备。',
				
				// titleText:'东方丝路“数字孪生”引擎“Orient Silk”建设的“智慧监狱一张图系统”，是按照《“数字法治&智慧司法”信息化体系建设指导意见》和《“数字法治&智慧司法”信息化体系建设实施方案》的要求，全面加快“智慧监狱”建设，立足推动监狱管理由“智能化”向“智慧化”的转变的指导思想建设的一套可视化管理系统。',
        // content1:'东方丝路“数字孪生”引擎“Clone Future”建设的“地震灾害应急风险管理与服务系统”,“智慧监狱一张图系统”是从监狱安防与运行管理的高度，达到了对监狱安全防范的集中管理，提高了监狱安全防范的整体联动响应能力和智能化管理水平，实现了监狱内各安防应用子系统集中管理、资源共享、统筹调度，形成了监狱综合安防一张图系统，从根本上提升了监狱全防范能力。',
        // content2:'建设基于数字孪生的智慧监狱管理体系，推动了监狱基础设施和管理的智能化，也体现了科技强警带来的监狱安防、矫正、执法、政务管理模式的变革，不仅是监狱管理模式的创新，更是现代化文明监狱的必然要求。'   
      }
    ])
    let activeObject=reactive({})
    onMounted(()=>{
      activeMenu('智慧交通')  
    })
    function activeMenu(val) {
      activeName.value=val
      for (var i=0;i<data.value.length;i++) {
        if (activeName.value==data.value[i].name) {
          Object.assign(activeObject,data.value[i])
        }
      }
    }
    return {
      activeName,
      data,
      activeObject,
      activeMenu  
    }
  }
}